import React from 'react';
import {BigPlayButton, Player} from "video-react";

const ReactVideo = ({ vURL, poster }) => {
  const handleVideoEnd = (player) => {
    player.currentTime = 0;
    player.load();
  }
  return(
      <Player
          playsInline
          poster={poster}
          src={vURL}
          onEnded={(event) => handleVideoEnd(event.target)}
      >
        <BigPlayButton position={'center'} />
      </Player>
  )
}
export default ReactVideo;